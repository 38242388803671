.foot{
    
    position: relative;
    width: 100%;
    height: auto;
    padding: 30px 0;
    
    overflow: hidden;
    border-top: 1px solid #70707074;
}

.foot .container{
  width: 100%;
  display: grid;
  grid-template-columns: 2fr 1fr 1fr 1fr;
  grid-gap: 20px;
}
.foot .container .sec h2{
  position: relative;
  font-weight:600;
  margin-bottom: 15px;
}
.foot .container .quicklinks{
  position: relative;
 
}
.foot .container .quicklinks ul li a{
  margin-bottom: 10px;
  display: inline-block;
  transition: 0.3s cubic-bezier(0.18, 0.89, 0.32, 1.28);
  
}
.foot .container .quicklinks ul li{
  overflow: hidden;
}
.foot .container .contact .info{
  position: relative;
}
.foot .container .contact .info li span{
  display: flex;
  grid-template-columns: 30px 1fr;
  margin-bottom: 16px;
  align-items: center;
  grid-gap: 12px;
}
.foot .container .contact .info ion-icon{
  font-size: 24px;
  color: var(--blueColor);
}
.foot .container .quicklinks a{
  position: relative;
  
}

/* hover kısmı */
.foot .container .quicklinks a:hover{
  
  transform: translateX(10px);
  
 
}
.foot .container .quicklinks a:hover::before{
  
  display: block;
  transition: 0.3s ease-in-out;
}

.foot .container .quicklinks a::before{
  content: " ";
  width: 10px;
  background-color: #000000;
  position: absolute;
  left: -15px;
  top: 50%;
  height: 2px;
  display: flex;
  align-items: center;
  transition: .2s;
}
.copyrightText{
    width: 100%;
    padding: 0px 100px 20px;
    color: var(--textGray);
   
  }
  .copyrightText .container{
    border-top: 1px solid rgba(0, 0, 0, 0.333);
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  /* socialIcon */

  .middle{
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;


  }
  .social-btn{
    display: inline-block;
    width: 50px;
    height: 50px;
    background-color:#f1f1f1;
    margin: 10px;
    border-radius:30% ;
    box-shadow: 0 5px 15px -5px #00000070;
    color: rgb(0 107 179);
    overflow: hidden;
    position: relative;
  }
  .social-btn i{
    line-height: 50px;
    font-size: 18px;
    transition: 0.2s linear;
  }
  .social-btn:hover i{
    transform: scale(1.3);
    color: #f1f1f1;
  }
  .social-btn::before{
    content: '';
    position: absolute;
    width: 120%;
    height: 120%;
    background: rgb(0 107 179);
    transform: rotate(45deg);
    left: -110%;
    top: 90%;

  }
  .social-btn:hover::before{
    animation: aaa 0.7s 1;
    top: -10%;
    left: -10%;
  }
  @keyframes aaa{
    0%{
      left: -110%;
      top: 90%;
    }50%{
      left: 10%;
      top: -30%;
    }100%{
      top: -10%;
      left: -10%;
    }
  }

.footerban{
  opacity: 0.1;
  position: absolute;
  transform: translateX(40%);
  z-index: -99;
  left: 0;
  bottom: -100px;
  
  

}

  @media (max-width:991px) {
    .foot{
      padding: 40px;
    }
    .foot .container{
      width: 100%;
      display: grid;
      grid-template-columns: repeat(2,1fr);
      grid-gap:20px
    }
    .copyrightText{
      padding: 20px 40px 30px;
    }
  }
  @media (max-width:768px) {
    .foot{
      padding: 40px;
      margin-top: 10rem;
    }
    .foot .container{
      width: 100%;
      display: grid;
      grid-template-columns: repeat(1,1fr);
      grid-gap:20px
    }
    .copyrightText{
      padding: 20px 40px 30px;
    }
    .copyrightText .container{
      display: flex;
      flex-direction: column;
    }
    .copyrightText .container p{
      text-align: center;
      margin-bottom: 10px;
    }
  }
  @media screen and (max-width:698px) {
     .footer-logo img{
      width: 75%;
     }
  }

  @media screen and (max-width:553px) {
    .middle{
      flex-wrap: wrap;
    }
  }