.contactt{
    height: 50vh;
    width: 100%;
    overflow: hidden;
    object-fit: cover;
    background-size: cover;
    background: linear-gradient(rgba(28, 28, 238, 0.42),rgba(79, 79, 244, 0.249)),url('../Assets/hakkimizda-back.jpg');
    background-position: 50%;
    background-repeat: no-repeat;
    
    background-size: cover;
    position: relative;
    display: flex;
    flex-direction:column;
    justify-content: center;
    clip-path: polygon(50% 0%, 100% 0, 100% 60%, 50% 100%, 50% 100%, 0% 60%, 0 0);
}
.contactt .headerbaslik{
    font-weight: 500;
}

.iletisimForm{
   display: grid;
   grid-template-columns: 1fr 1fr;
}
.phoneGif{
    display: flex;
    justify-content: center;
    align-items: center;
}

.contactText {
    margin: 10px;
    padding: 12px;
    border-radius: 10px;
    font-size: 18px;
    font-weight: 600;
    box-shadow: 0 2px 6px 0 rgba(31, 38, 135, 0.143);
    transition: all .4s ease;
    border: 2px solid #fff;
}

.contactText:hover {
    border: 2px solid rgb(239,158,31)
}

.map{
    height:500px;
    width: 100%;
    grid-column: span 2;
    -webkit-border-radius: 20px;
    -moz-border-radius: 20px;
    border-radius: 20px;
    overflow: hidden;
    position: relative;
    margin-top: 10rem;
    border: none;
    box-shadow: 0 5px 15px 0 rgba(31, 38, 135, 0.257);
}


.ClockBlg{
    display: grid;
    grid-template-columns: 1fr 1fr;
    margin-top: 10rem;
    background: rgb(34,66,125);
    background: linear-gradient(117deg, rgba(34,66,125,1) 45%, rgba(30,85,186,1) 100%);
    padding: 4rem;
    border-radius: 14px;
    position: relative;
    

    
}
.clock{
    margin: 10px;
    padding: 12px;
    border-radius: 10px;
    font-size: 18px;
    font-weight: 600;
    box-shadow: 0 2px 14px 0 rgba(0, 0, 0, 0.343);
    transition: all .4s ease;
    border: 2px solid #fff;
    align-items: center;
    justify-self: center;
    
   

    
}
.clock p{
    color: #fff;
    display: grid;
    grid-template-columns: 1.5fr 1fr 1fr;
    justify-content: center;
    

}
.clock .baslik3{
    color: #c3c0c0;
}
.clock:hover {
    border: 2px solid rgb(239,158,31)
}
.clockpng{
    transform: translateX(9%);
   }

@media screen and (max-width:768px) {
   .ClockBlg{
    display: flex;
    flex-direction: column-reverse;
   }
   .iletisimForm{
        display: flex;
        flex-direction: column;
   }
   .phoneGif img{
        height: 300px;
        width: auto;
   }
   
  .vertical{
    transform: rotate(0deg);
    position: static;
  }
  .contactt{
    display: grid;
    justify-content: center;
    justify-items: center;
    align-content: stretch;
  }
  .contactt .headerbaslik{
    font-size: 38px;
    font-weight: 700;
  }
  .iletisimBlg .baslik3{
    font-size: calc(1.9rem + 1vw);
  }
}
@media screen and (max-width:425px) {
    .clock p{
        color: #fff;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        
    
    }
}