.CounterUp{
    background: url('../Assets/iletisim-karavan\,.jpg');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    min-height: 50vh;
    display: flex;
    align-items: center;
    padding: 50px;
    background-attachment: fixed;
    position: relative;
}
.CounterUp::before{
    position: absolute;
    content: '';
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background-color: rgba(0, 0, 0, 0.8);
}
.CounterUp .content{
    position: relative;
    
    display: flex;
    width: 100%;
    height: 100%;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
}

.CounterUp .box{
    width: calc(25% - 30px);
    background: rgba( 255, 255, 255, 0.05 );
    box-shadow: 0 5px 15px 0 rgba(31, 38, 135, 0.257);
    backdrop-filter: blur( 7.5px );
    -webkit-backdrop-filter: blur( 7.5px );
    border-radius: 10px;
    border: 1px solid rgba(255, 255, 255, 0.109);
    border-radius: 5px;
    padding: 20px;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    flex-direction: column;
    
}

.CounterUp .box .counterIcon{
    font-size: 60px;
    background: rgb(34,66,125);
    background: linear-gradient(117deg, rgba(34,66,125,1) 0%, rgba(30,85,186,1) 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    
}
.CounterUp .counter{
    margin-bottom: 12px;
    font-weight: 600;
    color: #ffffff;
    display: flex;
    
}
.CounterUp .counterText{
    font-weight: 600;
    color: #c3c0c0;
}
@media screen and (max-width:1036px) {
    .CounterUp{
        padding: 50px 50px 0 50px ;
    }
    .CounterUp .box{
        width: calc(50% - 30px);
        margin-bottom: 50px;
    }
}
@media screen and (max-width:580px) {
    .CounterUp .box {
        width: 100%;
    }
    .CounterUp .cardBaslik{
        font-size: calc(1.4rem + 1vw);
    }
    .CounterUp .container{
        padding-inline: 0;
    }
}