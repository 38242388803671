header{
    display: flex;
    justify-content: center;
    padding: 10px 100px;
    position: fixed;
    align-items: center;
    padding: 2rem 1.5rem;
    color: #ffffff;
    width: 100%;
    z-index: 999;
    transition: all 300ms ease-in-out;
    padding-left: 12rem;
    padding-right: 12rem;
}
/* header{
  border-bottom: 1px solid #ffffff6e;
  background-color: #29292933;
  
} */
.mobile-head{
  display: none;
 }
.logo {
    width: auto;
    height: auto;
    transition: all 0.4s ease;
   
}
.logo::before{
  content: "";
  display: block;
  width: 240px;
  height: 175px;
  z-index: -1;
  align-items: center;
  top: 0;
  position: absolute;
  background-color: #ffffff;
  padding-bottom:30px ;    
  clip-path: polygon(50% 0%, 100% 0, 100% 60%, 50% 100%, 50% 100%, 0% 60%, 0 0);
   transition: all 0.4s ease;
}

.hamburger{
    display: none;
}
.nav-bar ul{
    display: flex;
    transition: all 0.3s ease;
    align-items: center;
    justify-content: center;
    
}
.nav-bar ul li a{
    display: block;
    color: #ffffffe1;
    font-size: 17px;
    transition: 0.2s;
    margin: 0 40px;
    position: relative;
    
   
    
   
}

.nav-bar ul li a::after {
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    height: 2px;
    background-color: rgb(0 107 179);
    content: '';
    opacity: 0;
    -webkit-transition: opacity 0.3s, -webkit-transform 0.3s;
    -moz-transition: opacity 0.3s, -moz-transform 0.3s;
    transition: opacity 0.3s, transform 0.3s;
    -webkit-transform: translateY(10px);
    -moz-transform: translateY(10px);
    transform: translateY(10px);
  }
  
 
  .nav-bar ul li a:hover::after {
    opacity: 1;
    -webkit-transform: translateY(0px);
    -moz-transform: translateY(0px);
    transform: translateY(0px);
  }
  .nav-bar ul li a:hover{
    color: #fff;
  }

  /* sub bar */
  .nav-bar ul li ul{
    position: absolute;
    display: block;
    background-color: #fff;
    border-radius: 7px;
    opacity: 0;
    z-index: -9999;
    visibility: hidden;
    pointer-events: auto;
    transition: 0.5s all ease;
    top: 90%;
  }
  .nav-bar ul li:hover ul{
    opacity: 1;
    top: 63%;
    visibility: visible;
    pointer-events:all;
  }
  .nav-bar ul li ul li{
    line-height: 1;
    width: 190px;
    border-radius: 5px;
    padding: 6px;


  }
  .nav-bar ul li ul li a{
    padding: 10px 0px;
    margin: 0 0;
    text-align: center;
    color: #000000c5;
    font-weight: 600;
    
  }
  .nav-bar ul li ul li a:hover{
    background-color:#e6e6e6 ;
    border-radius: 5px;
    color: #000;  
}
/* sub bar */
/* button */
.button {
    position: absolute;
    align-items: center;
    justify-content: center;
    transform: translateY(-50%);
    
    transition: all 0.3s ease-in-out;
    box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.2);
    padding-block: 1rem;
    padding-inline: 1.25rem;
    background-color: rgb(0 107 179);
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #ffff;
    gap: 10px;
    font-weight: bold;
    border: 2px solid #ffffff4d;
    outline: none;
    overflow: hidden;
    font-size: 15px;
  }
  
  .icon {
    width: 24px;
    height: 24px;
    transition: all 0.3s ease-in-out;
  }
  
  .button:hover {
   
    border-color: #fff9;
  }
  
  .button:hover .icon {
    transform: translate(4px);
  }
  
  .button:hover::before {
    animation: shine 1.5s ease-out infinite;
  }
  
  .button::before {
    content: "";
    position: absolute;
    width: 100px;
    height: 100%;
    background-image: linear-gradient(
      120deg,
      rgba(255, 255, 255, 0) 30%,
      rgba(255, 255, 255, 0.8),
      rgba(255, 255, 255, 0) 70%
    );
    top: 0;
    left: -100px;
    opacity: 0.6;
  }

  /* scroll navbar animation */
  /* .scroll-down header {
    
  }
   */
  .scroll-down header {
    filter: drop-shadow(0 -10px 20px #aaaaaa);
    color: rgb(0, 0, 0);
    background-color: #ffffffeb;
    padding: 1rem 1.5rem;
  }
 
  .scroll-up header {
    filter: drop-shadow(0 -10px 20px #aaaaaa);
    color: rgb(0, 0, 0);
    background-color: #ffffffeb;
    padding: 1rem 1.5rem;
  }
 
  .scroll-down header .header-contact{
    color: #000;
  }
  .scroll-up header .header-contact{
    color: #000;
  }
  .scroll-down header .header-contact .icon{
    color: #000;
  }
  .scroll-up header .header-contact .icon{
    color: #000;
  }
  .scroll-down header .toggleNavbar .icon{
    color: black;
  }
  .scroll-up header .toggleNavbar .icon{
    color: black;
  }
  .scroll-down .logo img{
    width: 200px;
  }
  .scroll-up .logo img{
    width: 200px;
  }
  .scroll-down .nav-bar a{
    color: #000000c5;
    font-weight: 600;
  }
  .scroll-up .nav-bar a{
    color: #000000c5;
    font-weight: 600;
  }
  .scroll-down .nav-bar a:hover{
    color: #000;
  }
  .scroll-up .nav-bar a:hover{
    color: #000;
  }
  .menu-is-open {
    overflow: hidden;
  }
  .menu-is-open header {
    filter: none;
  }
  .scroll-down .logo::before{
    content: "";
    display: block;
    width: 200px;
    height: 10px;
    z-index: -1;
    align-items: center;
    top: 0;
    position: absolute;
    background-color: transparent;
    padding-bottom:30px ;    
    clip-path: polygon(50% 0%, 100% 0, 100% 60%, 50% 100%, 50% 100%, 0% 60%, 0 0);
  }
  .scroll-up .logo::before{
    content: "";
    display: block;
    width: 200px;
    height: 10px;
    z-index: -1;
    align-items: center;
    top: 0;
    position: absolute;
    background-color: transparent;
    padding-bottom:30px ;    
    clip-path: polygon(50% 0%, 100% 0, 100% 60%, 50% 100%, 50% 100%, 0% 60%, 0 0);
  }

  /* hamburger menu */
  
  @media screen and (max-width: 1440px) {
    header {
        padding: 0 50px;
    }
   
}


@media screen and (max-width: 1320px) {
    header {
        padding: 0 50px;
    }
    .button {
     
      
      right: 20px;
      
    }
    .nav-bar ul li a{
      margin: 0 33px;
    }
}
@media screen and (max-width:1188px) {
  .nav-bar ul li a {
    font-size: 16px;
    margin: 0 25px;
  }
  .logo img{
   
    width: 230px;
   
  }
  .logo::before{
    padding-bottom: 15px;
    width: 220px;
    height: 150px;  }
  .button{
    font-size: 14px;
  }
  .logo img{
    width: 220px;
  }
}

@media screen and (max-width: 1100px) {
    header {
        padding: 0 30px;
    }
}
@media screen and (max-width: 1084px) {
   .desktop-head{
    display: none;
   }
   .mobile-head{
    display: flex;
   }
   .hamburger{
    width: 40px;
    height: 30px;
    position: relative;
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
    -webkit-transition: .5s ease-in-out;
    -moz-transition: .5s ease-in-out;
    -o-transition: .5s ease-in-out;
    transition: .5s ease-in-out;
    cursor: pointer;
    display: block;
  }
  
  .hamburger .line{
    display: block;
    margin-bottom: 5px;
    height: 5px;
    width: 100%;
    background: #ffffff;
    border-radius: 5px;
    opacity: 1;
    left: 0;
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
    -webkit-transition: .25s ease-in-out;
    -moz-transition: .25s ease-in-out;
    -o-transition: .25s ease-in-out;
    transition: .25s ease-in-out;
  }
  
  .hamburger .line:nth-child(1) {
    top: 0px;
  }
  
  .hamburger .line:nth-child(2) {
    top: 18px;
    width: 80%;
  }
  
  .hamburger .line:nth-child(3) {
    top: 36px;
  }
  
  
  .hamburger.open .line:nth-child(1) {
    transform: rotate(45deg) translate(13px, 6px);
  }
  
  .hamburger.open .line:nth-child(2) {
    opacity: 0;
    left: -60px;
  }
  
  .hamburger.open .line:nth-child(3) {
    transform: rotate(-40deg) translate(6px, -5px);
  }


  .logo::before{
    content: "";
    display: block;
    width: 0;
    height: 0;
    z-index: -1;
    align-items: center;
    top: 0;
    position: absolute;
    background-color: transparent;
    padding-bottom:30px ;    
    clip-path: polygon(50% 0%, 100% 0, 100% 60%, 50% 100%, 50% 100%, 0% 60%, 0 0);
  }
 
   .header-btn{
    display: none;
    
   }
   header{
    justify-content: space-between;
   }
   .nav-bar{
    height: 0;
    position: absolute;
    top: 106px;
    left:0;
    right: 0;
    width: 100vw;
    background-color: #ffffffeb;
    transition: 0.2s;
    align-items: center;
    justify-content: center;
    display: flex;
    overflow: hidden;
   }
 
   .nav-bar ul{
    display: block;
    opacity: 0;
    margin: 80px;
    text-align: center;
    transition: 0.3s;
   }
  .nav-bar ul li a{
    margin-bottom: 12px;
    font-size: 25px;
    font-weight: 600;
    color: #060606de;
  }
  .nav-bar.active{
    height: 450px;
  }
  .nav-bar.active ul{
    opacity: 1;
  }
  .scroll-up .nav-bar a{
    color: #060606de;
    font-weight: 600;
  }
  .scroll-up .nav-bar a:hover{
    color: #111111c3;
    font-weight: 600;
  }
  .scroll-up .hamburger .line{
    background: #000000;
   }
  .scroll-down .hamburger .line{
    background: #000000;
   }

/* subbar */
.nav-bar ul li ul{
  position: relative;
  background-color: #ffffff70;
  display: flex;
  align-items: center;
  flex-direction: column;
  margin: 0;
  opacity: 1;
  
}
/* .submenu{
  
} */
/* .submenu.open{
  display: flex;
} */

.nav-bar ul li ul li{
  line-height: 1;
  width: 190px;
  border-radius: 5px;
  padding: 6px;

}
.nav-bar ul li ul li a{
  padding: 10px 0px;
  margin: 0 0;
  
  text-align: center;
  color: #000000c5;
  font-weight: 600;
  font-size: 22px;
  
}
.nav-bar ul li ul li a:hover{
  background-color:#e6e6e6 ;
  border-radius: 5px;
  color: #000;  
}
.nav-bar .fa-caret-down{
  color: #000;
}


}
