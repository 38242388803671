.HowTo{
    background: rgb(34,66,125);
    background: linear-gradient(117deg, rgba(34,66,125,1) 45%, rgba(30,85,186,1) 100%);
  
}
.how p{
    color: #ddd;
}
.how h3{
    color: #c3c0c0;
}
.how h2{
    color: #c3c0c0;
    text-align: center;

}
.HowToCards{
    display: flex;
    flex-direction: row;
    width: 100%;
    align-items: flex-end;
    justify-content: space-between;
    

}
.howBox{
    height: 600px;
    width: 360px;
    box-sizing: border-box;
    overflow: hidden;
    
}

.howToCard{
    box-sizing: border-box;   
    height: 350px;
    
    width: 350px;
    border-radius: 8px;
    margin-bottom: 2rem;
    background-color: rgba(255, 255, 255, 0);
    clip-path: polygon(100% 0, 100% 50%, 100% 100%, 0% 100%, 0 50%, 0% 0%);
    transition: all 0.3s cubic-bezier(0.6, -0.28, 0.74, 0.05);
    overflow: hidden;
}
.howToCard img{
    height: 370px;
    width: 390px;
    background-image: url('../Assets/caravanIcon.svg');
    object-fit: cover;
    opacity: 0.9;
    transform: translateX(-10%);
    transition: all 0.3s cubic-bezier(0.6, -0.28, 0.74, 0.05);
    -webkit-filter: blur(0px);
    filter: blur(0px);
    overflow: hidden;
}
.howToCard:hover{
    clip-path: polygon(75% 0%, 100% 50%, 75% 100%, 0% 100%, 25% 50%, 0% 0%);
    
}
.howToCard:hover img{
    transform: translateX(0%);
    transform: scale(1.1);
    -webkit-filter: blur(1px);
    filter: blur(1px);
    opacity: 1;

}
.img::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 255, 0.5); /* Mavi renkte katman eklemek için rgba(0, 0, 255, 0.5) kullanılır */
    z-index: 34; /* Resmin önünde olması için z-index değeri ayarlanır */
  }



  @media screen and (max-width:1200px) {
    .HowToCards{
        flex-wrap: wrap;
    }
  }
  @media screen and (max-width:992px) {
    .HowToCards{
        justify-content: center;
    }
  }
  @media screen and (max-width:414px) {
    .howToCard img{
        height: 280px;
        width: 300px;
    }
    .howToCard{
        display: grid;
        height: 260px;
        width: 260px;
        align-content: space-around;
    }
    .howBox{
        height: 550px;
        width: 270px;
    }
  }