.bizLists{
    margin-top: 2.5rem;
    margin-bottom: 2.5rem;
}
.bizList .iconn{
    width: 12px;
    margin-bottom: 13px;
    font-size: 17px;
    background: rgb(34,66,125);
    background: linear-gradient(117deg, rgba(34,66,125,1) 0%, rgba(30,85,186,1) 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}
.bizImg{
    position: relative;
}
.bizImg img{
    border-radius: 15px;
    transition: all 1s ease;
}
.biz-img{
    width:650px;
    height:auto;
    background:'transparent';
    overflow:hidden;
    border-radius:15px;
    box-shadow:0 4px 10px rgba(42 94, 193, 0.635);
}
.banner-square{
    width:270px;
    height:auto;
    background:transparent;
    position:absolute;
    overflow:'hidden';
    top:70px;
    right:-55px;
    border-radius:115px 9px 129px 15px;
    box-shadow:0 3px 10px rgba(42, 94, 193, 0.635);
}

.bizbanner{
    box-shadow: 0 4px 10px rgba(42, 94, 193, 0.635);
    filter:grayscale(20%);
}
.bizImg .bannerabs{
    border-radius : 115px 9px 129px 15px;
}
.bizImg .bizbanner:hover{
   
    filter:grayscale(0);
    
}

.bizImg .bannersquare{
    position: absolute;
    top: -40px;
    right: -40px;
    z-index: -1;
}
@media screen and (max-width:1440px) {
    .biz-img .biz-img img{
        width:600px;
       
    }
    .banner-square ,.banner-square img{
        width:250px;
        height:auto;
        
    }
}
@media screen and (max-width:1300px) {
    .biz-img, .biz-img img{
        width:560px;
       
    }
    .banner-square , .banner-square img{
        width:220px;
        
        
    }
    .bizContent .baslik2{
        font-size: calc(1.2rem + 1vw);
    }
    .bizContent .icerikText{
        font-size: calc(0.3rem + 1vw);
    }
    .bizContent .bizList{
        font-size: calc(0.1rem + 1vw);
    }
}
@media screen and (max-width:1024px) {
    .biz-img, .biz-img img{
        width:420px;
       
    }
    .banner-square , .banner-square img{
        width:176px;
        
        
    }
    .bizImg .bannersquare{
        width: 180px;
        top: -30px;
        right: -30px;
    }
    
}
@media screen and (max-width:986px) {
    .bizText.gridIcerik{
        
        display: flex;
        flex-direction: column;
    }
    .bizText .iceriks{
        margin-top: 4rem;
    }
    .biz-img, .biz-img img{
        width:100%;
       
    }
    .banner-square , .banner-square img{
        display: none;
        
        
    }
    .bizImg .bannersquare{
        width: 70%;
        top: -25px;
        right: -26px;
    }
    .bizContent .baslik2{
        font-size: calc(1.7rem + 1vw);
    }
    .bizContent .icerikText{
        font-size: calc(0.8rem + 1vw);
    }
    .bizContent .bizList{
        font-size: calc(0.6rem + 1vw);
    }
    .bizContent .bizList i{
        font-size: calc(0.8rem + 1vw);
    }

}
@media screen and (max-width:425px) {
    .bizImg .bannersquare{
        width: 60%;
        top: -13px;
        right: -13px;
    }
    .bizContent .baslik2{
        font-size: calc(1.6rem + 1vw);
        margin-bottom: 3rem;
    }
    .bizContent .icerikText{
        font-size: calc(0.75rem + 1vw);
    }
    .bizContent .bizList{
        font-size: calc(0.65rem + 1vw);
    }
    .bizContent .bizList i{
        font-size: calc(0.8rem + 1vw);
       
    }

}
@media screen and (max-width:338px) {
    .bizContent .bizList{
        font-size: calc(0.6rem + 1vw);
    }
}