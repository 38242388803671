.hizmetlerr{
    height: 50vh;
    width: 100%;
    overflow: hidden;
    object-fit: cover;
    background-size: cover;
    background: linear-gradient(rgba(28, 28, 238, 0.42),rgba(79, 79, 244, 0.249)),url('../Assets/hakkimizda-back.jpg');
    background-position: 50%;
    background-repeat: no-repeat;
    
    background-size: cover;
    position: relative;
    display: flex;
    flex-direction:column;
    justify-content: center;
    clip-path: polygon(50% 0%, 100% 0, 100% 60%, 50% 100%, 50% 100%, 0% 60%, 0 0);
}
.hizmetlerr .headerbaslik{
    font-weight: 500;
}
.feature{
    display: grid;
    grid-template-columns: 1fr 1fr;
}
.grid_4 .nicdark_textevidence{
    width: 405px;    
}

.nicdark_activity {
    margin-left: 100px;
    float: left;

}
.nicdark_width_fix_50{
    position: absolute;
}

@media screen and (max-width:997px) {
    .feature{
        display: grid;
        grid-template-columns: 1fr;
        
    }
  
}

@media screen and (max-width:605px) {
    .grid_4 .nicdark_textevidence{
        width: 350px;
        white-space: wrap;
    }
    .nicdark_width_fix_50 {
        display: flex;
        position: relative;
        flex-direction: column;
        align-items: center;
    }
    .nicdark_activity {
        margin-left: 0px;

    }
}
@media screen and (max-width:380px) {
    .grid_4 .nicdark_textevidence{
        width: 300px;
        white-space: wrap;
    }
    
}
@media screen and (max-width:326px) {
    .grid_4 .nicdark_textevidence{
        width: 270px;
        white-space: wrap;
    }
    
}