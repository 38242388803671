.home{
    height: 100vh;
    width: 100%;
    overflow: hidden;
    object-fit: cover;
    background-size: cover;
    background: linear-gradient(rgba(0,0,1,.12),rgba(131, 131, 253, 0.249)),url('../../../public/img/pexels-asad-photo-maldives-1024981.jpg');
    background-position: 50%;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
    display: flex;
    flex-direction:column;
    justify-content: center;
  }
  .homeText{
    z-index: 99;
    color: #fff;
    display: flex;
    transform: translateY(50%);
    text-align: center;
    align-items: center;
    justify-content: center;
    
    
  }
  .baslikText{
    font-weight: 700;
    font-size: 2.4rem;
    transform: translateY(80%);
    background: #ef9f1fe2;
    border-radius: 5px;
    padding: 3px;


  }


  /* homeButton */

  .homeBtn {
    margin-top: 10rem;
    padding: 17px;
    position: relative;
    background-color: transparent;
    cursor: pointer;
    border: 2px solid #ffffff4d;
    background-color: rgb(0 107 179);
    overflow: hidden;
    border-radius: 9px;
    color: #fff;
    transition: all 0.5s ease-in-out;
  }
  
  .btn-txt {
    z-index: 1;
    font-weight: 800;
    letter-spacing: 4px;
  }
  
  .type1::after {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    transition: all 0.5s ease-in-out;
    background-color: rgb(0 107 179);
    border-radius: 30px;
    visibility: hidden;
    height: 10px;
    width: 10px;
    z-index: -1;
  }
  
  .homeBtn:hover {
    box-shadow: 1px 1px 200px rgb(0 107 179);
    color: #fff;
  }
  
  .type1:hover::after {
    visibility: visible;
    transform: scale(100) translateX(2px);
  }

  /* scroll to */
 
  #section07 a span {
    position: absolute;
    bottom: 18px;
    left: 50%;
    width: 24px;
    height: 24px;
    margin-left: -12px;
    border-left: 1px solid #ffffff;
    border-bottom: 1px solid #ffffff;
    -webkit-transform: rotate(-45deg);
    transform: rotate(-45deg);
    -webkit-animation: sdb07 2s infinite;
    animation: sdb07 2s infinite;
    opacity: 0;
    box-sizing: border-box;
  }
  #section07 a span:nth-of-type(1) {
    -webkit-animation-delay: 0s;
    animation-delay: 0s;
  }
  #section07 a span:nth-of-type(2) {
    bottom: 34px;
    -webkit-animation-delay: .15s;
    animation-delay: .15s;
  }
  #section07 a span:nth-of-type(3) {
    bottom: 50px;
    -webkit-animation-delay: .3s;
    animation-delay: .3s;
  }

.modalButton{
  
  border-top-right-radius: 4px;
  border-top-left-radius: 4px;
  top: 40%;
  right: 0px;
  background-color: cadetblue;
  color: aliceblue;
  transform: rotate(-90deg);

}

  @-webkit-keyframes sdb07 {
    0% {
      opacity: 0;
    }
    50% {
      opacity: 1;
    }
    100% {
      opacity: 0;
    }
  }
  @keyframes sdb07 {
    0% {
      opacity: 0;
    }
    50% {
      opacity: 1;
    }
    100% {
      opacity: 0;
    }
  }

@media screen and (max-width:1004px) {
  .headerbaslik{
    font-size: calc(2.5rem + 1vw);
  }
  .baslikText{
    margin-top: 1.5rem;
    font-size: calc(1.1rem + 1vw);
  }
}
