.contactButton{
    background-color: rgb(1, 6, 37);
    height: 170px;
    border-radius: 10px;
    align-items: center;
    display: flex;
    justify-content: space-around;
    scale: 0.9;
}
.contactButton .baslik2{
    margin-bottom: 0;
}
.contactBanner .span{
    font-weight: 400;
    font-size: 18px;
}
.bannerButton{
    
    color: white;
    padding: 20px;
    background-color: #3498db;
    border-radius: 7px;
    font-weight: 600;
    transition: all 0.4s ease;
}
.bannerButton:hover{
    transform: translateY(-3%);
}
@media screen and (max-width:768px) {
    .contactButton{
        flex-direction: column;
        align-items: center;
    }
    .contactBanner .baslik2{
        font-size: calc(1.2rem + 1vw);
        text-align: center;
    }
    .contactBanner .span{
        font-size: calc(.9rem + 1vw);
    }
    .bannerButton{
        font-size: calc(.9rem + 1vw);
        color: white;
        padding: 18px;
        background-color: #3498db;
        border-radius: 7px;
        font-weight: 600;
        transition: all 0.4s ease;
    }
    
}