
  .WA_Chat_Widget *{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: "Poppins", sans-serif;
   
    
}
.WA_Chat_Widget .WA_FloatingButton{
    position: fixed;
    width: 60px;
    height: 60px;
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: 0 4px 10px 0px rgba(0, 0, 0, 0.185);
    border-radius: 50%;
    background: #ffffff;
    cursor: pointer;
    z-index: 1000;
    transition: all 0.4s ease;
    
    
}
.WA_Chat_Widget .WA_FloatingButton svg{
    width: 35px;
    height: 35px;
    fill: #4fce5d;
}
.WA_Chat_Widget[data-position^="top-"] .WA_FloatingButton{
    top: 20px;
}
.WA_Chat_Widget[data-position^="bottom-"] .WA_FloatingButton{
    bottom: 20px;
}
.WA_Chat_Widget[data-position$="-left"] .WA_FloatingButton{
    left: 20px;
}
.WA_Chat_Widget[data-position$="-right"] .WA_FloatingButton{
    right: 20px;
}
.WA_Chat_Widget[data-position$="-center"] .WA_FloatingButton{
    left: 50%;
    transform: translateX(-50%);
}
.WA_Chat_Widget[data-position^="top-"] .WA_ChatBox{
    top: 110px;
}
.WA_Chat_Widget[data-position^="bottom-"] .WA_ChatBox{
    bottom: 110px;
}
.WA_Chat_Widget[data-position$="-left"] .WA_ChatBox{
    left: 30px;
}
.WA_Chat_Widget[data-position$="-right"] .WA_ChatBox{
    right: 30px;
}
.WA_Chat_Widget[data-position$="-center"] .WA_ChatBox{
    left: 50%;
    transform: translateX(-50%);
}
.WA_Chat_Widget .WA_ChatBox{
    position: fixed;
    width: 360px;
    height: auto;
    overflow: hidden;
    border-radius: 18px;
    background-color: #fff;
    box-shadow: 0 5px 25px 0px rgba(0, 0, 0, 0.1);
    z-index: 9999;
    transition: opacity 0.4s ease;
   
    
    
}
.WA_Chat_Widget .WA_ChatBox .WA_ChatBox_Header{
    width: auto;
    background: rgb(37, 211, 102);
    padding: 20px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-wrap: wrap;
    gap: 20px;
    opacity: 1;
}
.WA_Chat_Widget .WA_ChatBox .WA_ChatBox_Header .avatarBox{
    width: 65px;
    height: 65px;
    border-radius: 50%;
    overflow: hidden;
    
}
.WA_Chat_Widget .WA_ChatBox .WA_ChatBox_Header .avatarBox img{
    width: 100%;
    height: 100%;
    object-fit: contain;
}
.WA_Chat_Widget .WA_ChatBox .WA_ChatBox_Header .infoBox .name{
    color: #fff;
    font-weight: 600;
}
.WA_Chat_Widget .WA_ChatBox .WA_ChatBox_Header .infoBox .answer_time{
    color: #e4e4e4;
    font-size: 14px;
}
.WA_Chat_Widget .WA_ChatBox .WA_ChatBox_Header .WA_Close{
    background: transparent;
    color: #fff;
    border: none;
    outline: none;
    font-size: 20px;
    position: absolute;
    top: 10px;
    right: 10px;
    cursor: pointer;
    
}
.WA_Chat_Widget .WA_ChatBox .WA_ChatBox_Header .WA_Close svg{
    fill: #ffff;
}
.WA_Chat_Widget .WA_ChatBox .WA_ChatBox_Body{
    padding: 20px;
    background: #e6ddd4;
    position: relative;
    
}
.WA_Chat_Widget .WA_ChatBox .WA_ChatBox_Body::before{
    content: '';
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    background: url('../Assets/wpbg.jpg');
    opacity: 0.3;
    z-index: 0;
}
.WA_Chat_Widget .WA_ChatBox .WA_ChatBox_Body .message{
    max-width: 280px;
    background: #fff;
    padding: 20px;
    padding-top: 10px;
    border-radius: 8px;
    z-index: 1;
    
    position: relative;
}
.WA_Chat_Widget .WA_ChatBox .WA_ChatBox_Body .message::before{
    content: '';
    position: absolute;
    width: 20px;
    height: 20px;
    background: #fff;
    left: -10px;
    top: 0px;
    
    border-radius: 3px;
    clip-path: polygon(100% 0, 0 0, 100% 100%);
}
.WA_Chat_Widget .WA_ChatBox .WA_ChatBox_Body .message .username{
    color: darkgray;
    font-weight: 500;
}
.WA_Chat_Widget .WA_ChatBox .WA_ChatBox_Body .message .message_content{
    font-size: 14px;
    color: #000;
}
.WA_Chat_Widget .WA_ChatBox .WA_ChatBox_Footer{
    padding: 20px;
    background-color: #fff;
    width: 100%;
}
.WA_Chat_Widget .WA_ChatBox .WA_ChatBox_Footer .btn{
    width: 100%;
    outline: none;
    border: none;
    padding: 10px;
    border-radius: 1000px;
    font-size: 16px;
    cursor: pointer;
    text-decoration: none;
    
}
.WA_Chat_Widget .WA_ChatBox .WA_ChatBox_Footer .btn.btn-whatsapp{
    background: rgb(37, 211, 102);
    color: #fff;
    display: block;
    text-align: center;
    
}