.breadcrump{
    height: 50vh;
    width: 100%;
    overflow: hidden;
    object-fit: cover;
    background-size: cover;
    background: linear-gradient(rgba(28, 28, 238, 0.42),rgba(79, 79, 244, 0.249)),url('../Assets/hakkimizda-back.jpg');
    background-position: 50%;
    background-repeat: no-repeat;
    
    background-size: cover;
    position: relative;
    display: flex;
    flex-direction:column;
    justify-content: center;
    clip-path: polygon(50% 0%, 100% 0, 100% 60%, 50% 100%, 50% 100%, 0% 60%, 0 0);
}
.breadcrump .headerbaslik{
    font-weight: 500;
}


@media screen and (max-width:768px) {
    
   .vertical{
     transform: rotate(0deg);
     position: static;
   }
   .breadcrump{
     display: grid;
     justify-content: center;
     justify-items: center;
     align-content: stretch;
   }
   .breadcrump .headerbaslik{
     font-size: 38px;
     font-weight: 700;
   }
   .iletisimBlg .baslik3{
     font-size: calc(1.9rem + 1vw);
   }
 }