.About{
    height: 50vh;
    width: 100%;
    overflow: hidden;
    object-fit: cover;
    background-size: cover;
    background: linear-gradient(rgba(28, 28, 238, 0.42),rgba(79, 79, 244, 0.249)),url('../Assets/hakkimizda-back.jpg');
    background-position: 50%;
    background-repeat: no-repeat;
    
    background-size: cover;
    position: relative;
    display: flex;
    flex-direction:column;
    justify-content: center;
    clip-path: polygon(50% 0%, 100% 0, 100% 60%, 50% 100%, 50% 100%, 0% 60%, 0 0);
}

#hizmetlerr{
  display: grid;
  grid-template-columns: 1fr 1.5fr 1fr;
  grid-template-rows: 1fr;
  grid-column-gap: 30px;
  grid-row-gap: 0px;
  overflow: hidden;
}

.About .headerbaslik{
    font-weight: 500;
    
}

.vertical {
    display: flex;
    align-items: center;
    font-size: 14px;
    letter-spacing: 0.025rem;
    color: #fff;
    position: absolute;
    bottom: 2rem;
    left: 2.5rem;
    transform: rotate(-90deg);
    transform-origin: 0 0;
    color: rgba(255,255,255,0.5);
    z-index: 99;
  }
  .vertical a {
    color: inherit;
    text-decoration: none;
    border-bottom: 1px solid transparent;
    display: inline-block;
    transition-property: color;
    transition-duration: 0.25s;
    transition-timing-function: ease;
  }
  .vertical a:hover {
    color: rgb(0 107 179);
  }
  .vertical a:hover:after {
    color: rgba(255,255,255,0.5);
  }
  .vertical a:after {
    content: '/';
    padding: 0 0.5rem;
    display: inline-block;
  }
  .vertical span.destination {
    color: #fff;
    display: inline;
  }

  .aboutContent .icerikText{
    margin-top: 4rem;
    padding: 0 7rem 0 7rem;
    text-align: center;
  }


  @media screen and (max-width:623px) {
    .aboutContent .icerikText{
        margin-top: 4rem;
        padding: 0 2rem 0 2rem;
        text-align: center;
      }
  }

  .image-wrapper {
    position: relative;
    justify-content: center;
    margin-top: 40rem;
   
    translate: none;
    rotate: none;
    scale: none;
    opacity: 0.9996;
    transform: translate(60%, 5.0434%) translate3d(-65.34px, 69.2px, 0px) rotate(-3.9892deg);
  
  }
  .image-wrapper.iki{
    transform: translate(25%, 5.0434%) translate3d(-65.34px, 69.2px, 0px) rotate(-6.9892deg);
  }
  .shadow-lg img{
  box-shadow: 0 10px 15px -3px rgba(0,0,0,.1);   
  border-radius: 6px;
  }
  

  .image {
    width: 66%;
    overflow: hidden;
    position: relative;
    z-index: 5;
    aspect-ratio: 8/7;
    
  }
  .full-cover{
    display: block;
    height: 100%;
    -o-object-position: center;
    object-position: center;
    width: 100%;
  }
  
  .image:nth-child(1) {
    transform: rotate(15deg) translate(50%, 40%);
  }
  .iki .image:nth-child(1) {
    transform: rotate(-15deg) translate(-33%, 40%);
  }
  
  .image:nth-child(2) {
    opacity: 0.5;
    transform: rotate(0deg);
  }
  
  .iki .image:nth-child(2) {
    opacity: 0.5;
    transform: rotate(20deg);
  }
  
  .image img {
    width: 100%;
    height: auto;
    object-fit: cover;
  }
  #imagee2{
    width: 300px;
    height: 300px;
    
    
  }
  #imagee4{
    width: 300px;
    height: 300px;
    
    
  }
  #imagee{
    width: 250px;
    height: 250px;
    opacity: .5;
    
  }
  
  #imagee3{
    width: 250px;
    height: 250px;
    opacity: .5;
  }
  
  @media (min-width: 640px) {
    .image {
      width: 100%;
      transform: none;
    }
  }
  @media screen and (max-width:1024px){
    #hizmetlerr{
      display: flex;
      flex-direction: column;
    }
   
    .image-wrapper {
      position: relative;
      display: flex;
      justify-content: center;

      flex-wrap: wrap;
      margin-top: 6rem;
      translate: none;
      rotate: none;
      scale: none;
      transform: none;
      
    
    }
    
    .image-wrapper.iki{
      transform: none;
    }
    .image:nth-child(1) {
      transform: none;
      
      
    }
    .iki .image:nth-child(1) {
      transform: none;
      
    }
    
    .image:nth-child(2) {
      opacity: 1;
      transform: none;
    }
    
    .iki .image:nth-child(2) {
      opacity: 1;
      transform: none;
    }
    #imagee{
      margin-right: 10px;
    }
    #imagee3{
      margin-right: 10px;
    }
    #imagee2{
      margin-left: 10px;
      width: auto;
      height: 300px;
   
    }
    #imagee4{
      margin-left: 10px;
      width: auto;
      height: 300px;
     
    }
    #imagee{
      width: auto;
      height: 300px;
      opacity: 1;
    }
    
    #imagee3{
      width: auto;
      height: 300px;
      opacity: 1;
    }
  }

  @media screen and (max-width:768px) {
   
    .vertical{
      transform: rotate(0deg);
      position: static;
      
      
    }
    .About{
      display: grid;
      justify-content: center;
      justify-items: center;
      align-content: stretch;
    }
    .About .headerbaslik{
      font-size: 38px;
      font-weight: 700;
    }
    .vizyonsuzBslk{
      font-size: calc(1.8rem + 1vw);
    }
  }
@media screen and (max-width:690px){
  #imagee{
    margin-right: 10px;
    margin-left: 10px;
  }
  #imagee4{
    margin-right: 10px;
    margin-left: 10px;
  }
  #imagee2{
    margin-right: 10px;
    margin-left: 10px;
  }
  #imagee3{
    margin-right: 10px;
    margin-left: 10px;
  }
}