.hizmetler{
    
    display: grid;
    grid-template-columns: 1fr 1.5fr;
    grid-template-rows: 1fr;
    grid-column-gap: 10%;
    grid-row-gap: 0px;
    align-items: center;
  
}
.banners{
    
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(2, 1fr);
    grid-column-gap: 0px;
    grid-row-gap: 0px;
    position: relative;
    
}
.banner{
    position: relative;
   margin-bottom: 25px;
   margin-right: 25px;
   transition: all 1s ease;
}

/* hizmet cards */

.hizmetCard{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 300px;
    height: 300px;
    border-radius: 7px; 
    box-shadow: 0 5px 15px 0 rgba(31, 38, 135, 0.257);
    background: rgb(34,66,125);
    background: linear-gradient(117deg, rgba(34,66,125,1) 45%, rgba(30,85,186,1) 100%);
    transition: all 1s ease;
    display: flex;
    align-items: center;
    justify-content: center;
    

}

.hizmetCard img{
    display: block;
    margin: 0 auto;
}
.hizmetCard p{
   display: flex;
   position: absolute;
   font-weight: 800;
   color: #ffffff;
   bottom: 0;
   padding: 5px;
   border-radius: 5px;
   width: 90%;
 
   align-items: center;
   justify-content: center;
   margin-bottom: 5px;
}

/* .banner:nth-child(2) .hizmetCard p{
    background: rgba(34, 66, 125, 0.698);
    background: linear-gradient(117deg, rgba(34, 66, 125, 0.823) 45%, rgba(30, 85, 186, 0.811) 100%);
    
}
.banner:nth-child(3) .hizmetCard p{
    background: rgba(34, 66, 125, 0.698);
    background: linear-gradient(117deg, rgba(34, 66, 125, 0.823) 45%, rgba(30, 85, 186, 0.811) 100%);

} */
.banner:nth-child(4) .hizmetCard p{
   font-size: 14px;

}

.banner:nth-child(2) .hizmetCard{width: 270px; height: 270px; background: rgb(239,158,31);
    background: linear-gradient(117deg, rgba(239,158,31,1) 0%, rgba(213,182,22,1) 100%);}
.banner:nth-child(3) .hizmetCard{width: 250px; height: 250px; background: rgb(239,158,31);
    background: linear-gradient(117deg, rgba(239,158,31,1) 0%, rgba(213,182,22,1) 100%); }
.banner:nth-child(4) .hizmetCard{width: 240px; height: 240px; }

.banner:nth-child(2) .hizmetCard{
    margin-top: -45px;
}
.banner:nth-child(4) .hizmetCard{
    margin-top: -80px;
    left: 45%;
    
}
.banner:nth-child(3) .hizmetCard{
    margin-top: -25px;
    left: 44%;
    
}

.banner:nth-child(1),.banner:nth-child(3){margin-top: 50px;}
.banner:nth-child(4){margin-top: -50px;}

@media screen and (max-width:1206px) {
    .hizmetler{
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        align-content: center;
        justify-content: center;
    }
    .hizmetler .baslik2{
        text-align: center;
    }
    .hizmetler .icerikText{
        text-align: center;
    }
    .yazilar{
        margin-bottom: 3.5rem;
    }
}
@media screen and (max-width:986px) {
 
    .hizmetler .baslik2{
        font-size: calc(1.7rem + 1vw);
    }
    .hizmetler .icerikText{
        font-size: calc(0.8rem + 1vw);
    }

}
@media screen and (max-width:767px) {
    .hizmetler{
        display: flex;
        align-items: stretch;
    }
    
    .banners{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
       
    
    }
    .banner{
        margin-right: 0;
    }
    .banner:nth-child(2) .hizmetCard{
        margin-top: 0px;
    }
    .banner:nth-child(4) .hizmetCard{
        margin-top: 0px;
        left: 50%;
        
    }
    .banner:nth-child(3) .hizmetCard{
        margin-top: 0;
        left: 50%;
        
    }
    .banners .hizmetCard{
        position: relative;
        align-items: center;
        justify-content: center;

    }
    .banner #bannerblue{display: none;}
    .banner:nth-child(2) .hizmetCard{width: 300px; height: 300px;}
    .banner:nth-child(3) .hizmetCard{width: 300px; height: 300px; }
    .banner:nth-child(4) .hizmetCard{width: 300px; height: 300px;}
    .banner:nth-child(4){margin-top: 0px;}


}

@media screen and (max-width:425px) {
    .bizImg .bannersquare{
        width: 60%;
        top: -20px;
        right: -20px;
    }
    .hizmetler .baslik2{
        font-size: calc(1.6rem + 1vw);
        margin-bottom: 3rem;
    }
    .hizmetler .icerikText{
        font-size: calc(0.75rem + 1vw);
    }

}

