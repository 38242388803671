.vidGallery{
    margin-top: 7rem;
    -webkit-column-count:3;
    -moz-column-count: 3;
    column-count: 3;
    -webkit-column-width: 33%;
    -moz-column-width: 33%;
    column-width: 33%;
    padding: 0 12px;

}
.vidGallery .videos{
    -webkit-transition: all 350ms ease;
    transition: all 350ms ease;
    cursor: pointer;
    margin-bottom: 12px;
}
.vidGallery video{
    height: 100%;
    width: 100%;
    border-radius: 7px;
}
.vidGallery .pics:hover{
     filter: opacity(.8);
}


@media screen and (max-width:991px) {
    .vidGallery{
        -webkit-column-count:2;
        -moz-column-count: 2;
        column-count: 2;
        
    
    }
}
@media screen and (max-width:480px) {
    .vidGallery{
        -webkit-column-count:1;
        -moz-column-count: 1;
        column-count: 1;
        -webkit-column-width: 100%;
        -moz-column-width: 100%;
        column-width: 100%;
        
    
    }
}


/* model */

.model{
    width: 100%;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #000;
    visibility: hidden;
    opacity: 0;
    transform: scale(0);
    overflow: hidden;
    z-index: 9999;
    transition: opacity .4s ease,visibility .4s ease, transform .5s ease-in-out;

}

.models{
    
    max-width: 100%;
    height: auto;
    max-height: 100%;
   
    line-height: 0;
    box-sizing: border-box;
    padding: 20px 0 20px;
    margin: 0 auto;
}
.models.open i{
    position: fixed;
    cursor: pointer;
    top: 10px;
    right: 25px;
    height: 2rem;
    font-size: 30px;
    width: 2rem;
    background-color: rgba(0, 0, 0, .4);
    padding: 5px;
    color: #fff;
}