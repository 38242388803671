@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@500;600;700;800;900&display=swap');

/* RESET */

/* scroll settings */
body::-webkit-scrollbar {
  width: 0.5em; 
}
/* output */
body::-webkit-scrollbar-thumb {
  
  
  
  background-image: -webkit-gradient(linear,
                     left bottom,
                     left top,
                     color-stop(0.44, rgb(107, 107, 107)),
                     color-stop(0.72, rgb(84, 84, 84)),
                     color-stop(0.86, rgb(39, 39, 39)));
}
body::-webkit-scrollbar-thumb:hover{
  opacity:1;
}
body::-webkit-scrollbar-track {
  
  background-color: transparent;
  
}

body::-webkit-scrollbar {
  width: 0.5rem;
  height: 0.5rem;
 
}
/* scroll settings */


*{
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  font-family:'Montserrat',sans-serif ;
  
}
li { list-style: none; }
  
a {
  text-decoration: none;
  color: inherit;
}
span{
  display: inline;
}
a,
img,
time,
span,
input,
button,
ion-icon { display: block; }

img { height: auto; }

input,
button {
  background: none;
  border: none;
  font: inherit;
}

input { width: 100%; }

button { cursor: pointer; }

ion-icon { pointer-events: none; }

html {
  font-family: var(--ff-rubik);
  font-size: 10px;
  scroll-behavior: smooth;
}

body {
  background-color: var(--white);
  color: var(--onyx);
  font-size: 1.6rem;
  line-height: 1.7;
 
}
.flex{
  display: flex;
  align-items: center;
}
.grid{
  display: grid;
  align-items: center;
  justify-items: center;
  gap: 1rem;
}
.gridIcerik{
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr;
    grid-column-gap: 10%;
    grid-row-gap: 0px;
    align-items: center;
}
input{
  font-size: 100%;
}
.spanText{
  font-size: 14px;
}

:focus-visible { outline-offset: 4px; }

::-webkit-scrollbar { width: 12px; }

::-webkit-scrollbar-track { background-color: var(--cultured-2); }

::-webkit-scrollbar-thumb {
  background-color: hsl(0, 0%, 80%);
  border: 2px solid var(--cultured-2);
}

::-webkit-scrollbar-thumb:hover { background-color: hsl(0, 0%, 70%); }

/* ROOT */

:root{
  --grayColor:#454545;
  --redColor:#eb2f5b;
  --purpleColor:rgb(177 109 217);
  --lightOrangeColor:#FFA559;
  --titleColor:#454545;
  --blueColor:#2f5beb;
  --textGray:#797979;
  --lightBlueColor:#03a4ed;
  --plazaBlue:#222341;

}
.headerbaslik{
  color: #fff;
  font-size: calc(2.5rem + 2vw);
}
.kucukbaslik{
  position: relative;
  font-size: calc(0.2rem + 1vw);
  letter-spacing: -.01em;
  line-height: 1.3;
  margin-bottom: 5rem;
  color: #45454589;
  transition: background-size cubic-bezier(.1,.5,.5,1) 0.5s;
  
}

.baslik{
  text-align: center;
  font-size: calc(3.4rem + 1vw);
  letter-spacing: -.01em;
  line-height: 1.3;
  margin-bottom: 5rem;
  color: #454545;
  transition: background-size cubic-bezier(.1,.5,.5,1) 0.5s;
 }
.baslik2{
  font-size: calc(1.5rem + 1vw);
  letter-spacing: -.01em;
  line-height: 1.3;
  margin-bottom: 5rem;
  color: #454545;
  transition: background-size cubic-bezier(.1,.5,.5,1) 0.5s;
 }
 .baslik3{
  text-align: center;
  font-size: calc(1.5rem + 1vw);
  letter-spacing: -.01em;
  line-height: 1.3;
  margin-bottom: 5rem;
  color: #454545;
  transition: background-size cubic-bezier(.1,.5,.5,1) 0.5s;
 }
 .cardBaslik{
  text-align: center;
  font-size: calc(1rem + 1vw);
  letter-spacing: -.01em;
  line-height: 1.3;
  margin-bottom: 2.5rem;
  color: #454545;
  transition: background-size cubic-bezier(.1,.5,.5,1) 0.5s;
 }
 .icerikText{
  word-spacing:10px;
  font-size: 17px;
  font-weight: 600;
  line-height: 180%;
 }
 .cardText{
  color: #454545;
  word-spacing:2px;
  font-size: 16px;
  font-weight: 600;
  line-height: 180%;
 }
/* Yazı Tipleri */
@font-face {
  font-family: 'vizyonsuzBaslik';
  src: url('../public/fonts/Amalfi\ Coast.ttf') format('truetype');
  font-display: swap;
 }

.vizyonsuzBslk{
  font-family: 'vizyonsuzBaslik',sans-serif;
  text-align: center;
  margin-top: 5rem;
  font-size: calc(1.5rem + 1vw);
}

/* //container */
.container{padding-inline: 15px; padding-top: 6rem; padding-bottom: 3rem; overflow: hidden; }

 @media screen and (min-width:640px) {
  .container {
    max-width: 540px;
    width: 100%;
    margin-inline: auto;
  }
 }
 @media screen and (max-width:600px) {
  .container {
    max-width: 400px;
    width: auto;
    margin-inline: auto;
   
  }
 
 }
 @media screen and (min-width:768px) {
  .container {
    max-width: 708px;
    width: 100%;
    margin-inline: auto;
  }
 }
 @media screen and (min-width:992px) {
  .container {
    max-width: 932px;
    width: 100%;
    margin-inline: auto;
  }
 }

 @media screen and (min-width:1024) {
  .container {
    max-width: 964px;
    width: 100%;
    margin-inline: auto;
  }
  
 }
 @media screen and (min-width:1200px) {
  .container {
    max-width: 1140px;
    width: 100%;
    margin-inline: auto;
  }
 }
 @media screen and (min-width:1440px) {
  .container {
    max-width: 1380px;
    width: 100%;
    margin-inline: auto;
  }
 }
 @media screen and (max-width:1024px) {
  .cardBaslik{
    font-size: calc(1.6rem + 1vw);
  }
 }