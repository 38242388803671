/* Modal Button */
.modalButton {
    top: 40%;
    left: 50%;
    
    z-index: -1;
    padding: 12px 24px;
  }
  
  
  /* Modal */
  .overlay {
    background-color: rgba(0, 0, 0, 0.601);
    position: fixed;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    z-index: 99999;
  }
  .modall{
    position: fixed;
    top: 50%;
    right: -80px;
    z-index: 999;
  }
  .modalContainer {
    max-width: 800px;
    width: 100%;
    position: fixed;
    top: 55%;
    border-radius: 7px;
    left: 50%;
    transform: translate(-50%, -50%);
    display: grid;
    grid-template-row: 1fr 1fr;
    background-color: #ffffff;
    box-shadow: 0px 0px 18px 0px rgba(0, 0, 0, 0.75);
    border-radius: 8px;
    
  }
  
  .modalRight img {
    width: 100%;
    border-top-right-radius: 7px;
    border-top-left-radius: 7px;
    object-fit: cover;
    border-top-left-radius: 8px;
    border-bottom-left-radius: 8px;
  }
  
  .modalRight {
    width: 100%;
  }
  
 .modalRight i {
    position: absolute;
    top: 8px;
    right: 8px;
    color: #ffffff;
    font-size: 20px;
    cursor: pointer;
  }
  
  .modalRight .content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    margin-top: 3rem;
    padding: 1rem 2rem;
  }
  
  .btnContainer {
    display: flex;
    padding: 1rem 1rem;
  }
  .btnContainer button {
    width: 100%;
    margin: .5rem;
    padding: 16px 0;
    /* border: none; */
   
    /* color: #ffffff; */
  }
  
  .btnPrimary {
    border-radius: 5px;
    background-color: rgb(0 107 179);
    color: white;
    transition: all 0.4s ease;
  }
  .btnPrimary:hover {
   
    background-color: rgba(0, 107, 179, 0.807);
    color: white;
  }
  
  .btnOutline {
    /* background-color: #a76a99; */
    background-color: white;
    background-color: rgb(0 107 179);
  }
  
  .bold {
    font-weight: 600;
  }
  
  @media screen and (max-width: 500px) {
   
    .modalContainer {
      flex-direction: column;
      top: 0;
      left: 0;
      transform: none;
      width: 100%;
      height: 100vh;
     
    }
    .modalRight img {
      width: 100%;
      max-height: 70vh;
      object-fit: cover;
    }
  
    .heading {
      margin: 1rem;
    }
  }